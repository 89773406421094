<template>
    <div>
        <asset-navbar :filterMarket="filterMarket">
            <template v-slot:title>
                <span v-if="market" class="ml-1">{{ market }} market</span>
                <span v-else class="ml-1">???</span>
            </template>
            <template v-slot:subTitle>
            </template>
        </asset-navbar>

        <div v-if="filterMarket && assets.length">
            <div v-if="filterMarket == 'stock' || filterMarket == 'crypto'">

                <!--<rounded-container variant="dark">
                    <template v-slot:title>priority</template>
                    
                    <b-container class="p-0">
                        <b-row v-for="(asset, rowIndex) in assets.filter(asset => 
                                asset.market == filterMarket 
                                && asset.conviction == '100k'
                                )" :key="asset.symbol"
                            :class="rowClass(rowIndex)"
                            class="p-3">
                            <b-col>
                                <asset-symbol :asset="asset"/>
                            </b-col>
                            <b-col class="text-right">
                                <span v-if="!hasMonthlyDraw(asset)">
                                    <b-button size="sm" @click.prevent="addDraw(asset)">add draw</b-button>
                                </span>
                                <span v-else class="text-secondary">drew ✓</span>
                            </b-col>
                        </b-row>
                    </b-container>
                </rounded-container>-->


                <rounded-container variant="dark">
                    <template v-slot:title>
                        high conviction
                        <span class="text-secondary">{{ assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.acts_like_conviction == 'high'
                            ).length }}</span>
                    </template>

                    <list-assets-table 
                        ref="assetTable" 
                        :assetList="assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.acts_like_conviction == 'high'
                            )" 
                        />
                </rounded-container>


                <rounded-container variant="dark">
                    <template v-slot:title>
                        no conviction set
                        <span class="text-secondary">{{ assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.acts_like_conviction == null
                            ).length }}</span>
                    </template>

                    <list-assets-table 
                        ref="assetTable" 
                        :assetList="assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.acts_like_conviction == null
                            )" 
                        />
                </rounded-container>


                <rounded-container variant="dark">
                    <template v-slot:title>
                        low conviction
                        <span class="text-secondary">{{ assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.acts_like_conviction == 'low'
                            ).length }}</span>
                    </template>

                    <list-assets-table 
                        ref="assetTable" 
                        :assetList="assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.acts_like_conviction == 'low'
                            )" 
                        />
                </rounded-container>


                <rounded-container>
                    <template v-slot:title>
                        snoozed
                        <span class="text-secondary">{{ assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.acts_like_conviction == 'snoozed'
                            && asset.status != 'disabled'
                            ).length }}</span>
                    </template>

                    <list-assets-table 
                        ref="assetTable" 
                        :assetList="assets.filter(asset => 
                            asset.market == filterMarket 
                            && asset.acts_like_conviction == 'snoozed'
                            && asset.status != 'disabled'
                            )" 
                        />
                </rounded-container>

            </div>
        </div>
        <div v-else>
            loading screen
        </div>
    </div>
</template>

<script>

import formatter from '../mixins/formatter'
import assetMixin from '../mixins/assetMixin'

import ListAssetsTable from '../components/asset/ListAssetsTable.vue';
import AssetNavbar from '../components/AssetNavbar.vue';
import RoundedContainer from '../components/style/RoundedContainer.vue';
//import AssetSymbol from '../components/style/AssetSymbol.vue'

export default {
    name: 'Assets',
    mixins: [formatter, assetMixin],
    components: {
        ListAssetsTable,
        RoundedContainer,
        AssetNavbar,
        //AssetSymbol
    },
    props: ['market', 'assets'],
    data() {
        return {
            filterMarket: '',
            highConvictionAssets: [],
            lowConvictionAssets: [],
            snoozedAssets: [],
            otherAssets: [],
        }
    },
    created() {
        this.filterMarket = this.market;
    },
    methods: {
        hasMonthlyDraw(asset) {
            if (asset.cached_stats.draws_this_month) return true
            return false
        }
    },
    watch: {
        $route(to) {
            // (to, from)
            // force content refresh when switching props
            this.filterMarket = to.params.market; 
        }
    }
};
</script>

<style>
.asset_settings {
    color: #fff;
    padding: 12px;
    background-color: #729DCB;
}
.asset_settings a {
    text-decoration: underline;
    color: #fff;
}
</style>
<template>
    <rounded-container variant="dark">
        <template v-slot:title>From last conviction change <span class="text-secondary">{{ formatTimeFromISO(currentAsset.meta.conviction_change_time + 'Z') }}</span></template>

        <b-form @submit="onSubmit" class="p-3">
            <b-row class="mb-4 text-center">
                <b-col>
                    <h3 v-if="currentAsset.cached_stats.since_last_conviction_change.net >= 0">
                        ${{ currentAsset.cached_stats.since_last_conviction_change.net }}
                    </h3>
                    <h3 v-else class="text-danger">
                        -${{ Math.abs(currentAsset.cached_stats.since_last_conviction_change.net) }}
                    </h3>
                    net
                </b-col>

                <b-col class="text-secondary">
                    <h3>{{ currentAsset.cached_stats.since_last_conviction_change.buys }}</h3> 
                    buys
                </b-col>
                <b-col class="text-secondary">
                    <h3>{{ currentAsset.cached_stats.since_last_conviction_change.reinvested }}</h3>
                    <b-icon-arrow-down-up />
                </b-col>
                <b-col class="text-secondary">
                    <h3>{{ currentAsset.cached_stats.since_last_conviction_change.exit }}</h3>
                    exits    
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="meta-conviction" label="Conviction:" label-for="asset-conviction">
                        <b-form-select 
                            id="asset-conviction"
                            v-model="form.conviction" 
                            :options="convictionOptions"
                        >
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="meta-vault-bucket" label="Vault bucket:" label-for="asset-vault-bucket">
                        <b-form-select 
                            id="asset-vault-bucket"
                            v-model="form.vaultBucket" 
                            :options="vaultBucketOptions"
                        >
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="meta-vault-target" label="Vault target:" label-for="asset-vault-target">
                        <b-form-input id="asset-vault-target" v-model="form.vaultTarget" placeholder="# of shares"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="meta-one-liner" label="Strategy One Liner:" label-for="asset-one-liner">
                        <b-form-input id="asset-one-liner" v-model="form.oneLiner" placeholder="One liner"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="meta-can-trade" label="Can long:" label-for="asset-can-long">
                        <b-form-select 
                            id="asset-can-long"
                            v-model="form.canLong" 
                            :options="canLongOptions"
                        >
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>

            <div v-if="buttonLoadingState">
                <b-button type="submit" variant="primary"><b-spinner small type="grow"></b-spinner></b-button>
            </div>
            <div v-else>
                <b-button type="submit" variant="primary">Update {{ currentAsset.symbol }}</b-button>
            </div>
        </b-form>
    </rounded-container>
</template>

<script>

import axios from 'axios';
import formatter from '../../mixins/formatter'
import bucketMixin from '../../mixins/bucketMixin'
import RoundedContainer from '../style/RoundedContainer.vue';

export default {
  components: { RoundedContainer },
    props: ['currentAsset'],
    name: 'EditAssetMeta',
    mixins: [formatter, bucketMixin],
    data() {
        return {
            form: {
                conviction: null,
                canLong: null,
                vaultBucket: null,
                vaultTarget: null,
                oneLiner: null
            },
            convictionOptions: [
                { value: '100k', text: '100K' },
                { value: 'high', text: 'High' },
                { value: null, text: 'Regular' },
                { value: 'low', text: 'Low' },
                { value: 'snoozed', text: 'Snoozed' }
            ],
            canLongOptions: [
                { value: null, text: 'No' },
                { value: true, text: 'Yes' }
            ],
            vaultBucketOptions: [
                { value: null, text: '- None -' }
            ],
            buttonLoadingState: false,
        }
    },
    created() {
        if (this.currentAsset.meta) {
            if ('conviction' in this.currentAsset.meta) {
                this.form.conviction = this.currentAsset.meta.conviction;
            }
            if ('canLong' in this.currentAsset.meta) {
                this.form.canLong = this.currentAsset.meta.canLong
            }
            if ('vaultBucket' in this.currentAsset.meta) {
                this.form.vaultBucket = this.currentAsset.meta.vaultBucket
            }
            if ('vaultTarget' in this.currentAsset.meta) {
                this.form.vaultTarget = this.currentAsset.meta.vaultTarget
            }
            if ('oneLiner' in this.currentAsset.meta) {
                this.form.oneLiner = this.currentAsset.meta.oneLiner
            }
        }

        this.vaultBucketOptions = this.vaultBucketOptions.concat(this.bucketFormOptions);
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()

            this.$emit('request-asset-refresh');

            this.buttonLoadingState = true;
            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios.post(
                    this.$store.state.baseURL[this.$store.state.env] + '/api/asset/' + this.currentAsset.symbol + '/meta', 
                    JSON.stringify(this.form), {
                        headers: {
                            // Overwrite Axios's automatically set Content-Type
                            'Content-Type': 'application/json'
                        }
                    }
                )
                .then((response) => {
                    let assetList = [response.data];

                    this.$store.dispatch('updateAssets', assetList);
                    this.buttonLoadingState = false; 
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
}
</script>

<style>

</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('asset-navbar',{attrs:{"filterMarket":_vm.filterMarket},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.market)?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.market)+" market")]):_c('span',{staticClass:"ml-1"},[_vm._v("???")])]},proxy:true},{key:"subTitle",fn:function(){return undefined},proxy:true}])}),(_vm.filterMarket && _vm.assets.length)?_c('div',[(_vm.filterMarket == 'stock' || _vm.filterMarket == 'crypto')?_c('div',[_c('rounded-container',{attrs:{"variant":"dark"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" high conviction "),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.assets.filter(asset => asset.market == _vm.filterMarket && asset.acts_like_conviction == 'high' ).length))])]},proxy:true}],null,false,793869312)},[_c('list-assets-table',{ref:"assetTable",attrs:{"assetList":_vm.assets.filter(asset => 
                        asset.market == _vm.filterMarket 
                        && asset.acts_like_conviction == 'high'
                        )}})],1),_c('rounded-container',{attrs:{"variant":"dark"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" no conviction set "),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.assets.filter(asset => asset.market == _vm.filterMarket && asset.acts_like_conviction == null ).length))])]},proxy:true}],null,false,2969490328)},[_c('list-assets-table',{ref:"assetTable",attrs:{"assetList":_vm.assets.filter(asset => 
                        asset.market == _vm.filterMarket 
                        && asset.acts_like_conviction == null
                        )}})],1),_c('rounded-container',{attrs:{"variant":"dark"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" low conviction "),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.assets.filter(asset => asset.market == _vm.filterMarket && asset.acts_like_conviction == 'low' ).length))])]},proxy:true}],null,false,1448504256)},[_c('list-assets-table',{ref:"assetTable",attrs:{"assetList":_vm.assets.filter(asset => 
                        asset.market == _vm.filterMarket 
                        && asset.acts_like_conviction == 'low'
                        )}})],1),_c('rounded-container',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" snoozed "),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.assets.filter(asset => asset.market == _vm.filterMarket && asset.acts_like_conviction == 'snoozed' && asset.status != 'disabled' ).length))])]},proxy:true}],null,false,850371172)},[_c('list-assets-table',{ref:"assetTable",attrs:{"assetList":_vm.assets.filter(asset => 
                        asset.market == _vm.filterMarket 
                        && asset.acts_like_conviction == 'snoozed'
                        && asset.status != 'disabled'
                        )}})],1)],1):_vm._e()]):_c('div',[_vm._v(" loading screen ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
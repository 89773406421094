<template>
    <div class="p-2">
        <line-chart v-if="showChart" :chartData="chartData" :chartOptions="chartOptions" class="pb-3 holdings-breakdown-chart" />
    </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';

import formatter from '@/mixins/formatter';

export default {
    name: 'HoldingsBreakdownChart',
    props: ['monthlyStats', 'currentAsset'],
    mixins: [formatter],
    components: {
        LineChart
    },
    data() {
        return {
            byShares: null,
            chartOptions: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                responsive: true,
                maintainAspectRatio: false,
                //stacked: false,
                scales: {
                    yAxes: [
                        {
                            id: 'y1',
                            type: 'linear',
                            display: true,
                            position: 'right',
                            // gridLines: {
                            //     display: true
                            // },
                            //stacked: false
                            ticks: { fontColor: '#92B9BD', /*beginAtZero: true*/ }
                        },
                        {
                            id: 'y2',
                            type: 'linear',
                            display: true,
                            position: 'left',
                            ticks: { fontColor: '#D63230', /*beginAtZero: true*/ }
                            // ticks: {
                            //     display: true
                            // },
                            // gridLines: {
                            //     display: false
                            // },
                            // grid: {
                            //     drawOnChartArea: false, // only want the grid lines for one axis to show up
                            // },
                            // scaleLabel: {
                            //     display: true,
                            //     labelString: 'Price'
                            // }
                        }
                    ]
                },
                legend: {
                    labels: {
                        filter: function(item) {
                            // Logic to remove a particular legend item goes here
                            return !item.text.includes('###');
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.byShares = this.computeChartData('date', [
            {
                key: 'free_shares',
                label: 'Free Shares',
                backgroundColor: '#F2F79E',
            },
            {
                key: 'shares_holdings',
                label: 'Holdings',
                backgroundColor: '#92B9BD',
            },
            {
                key: 'point_in_time_price',
                label: '###',
                borderColor: '#D63230',
                yAxisID: 'y2',
                borderWidth: 2,
            }
        ]);
    },
    computed: {
        showChart() {
            return this.byShares ? true : false;
        },
        chartData() {
            return { 
                labels: this.byShares.labels, 
                datasets: this.byShares.datasets 
            }
        }
    },
    methods: {
        computeChartData(labelSource, dataLookup) {
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ]
            let labels = [];
            let datasets = [];
            
            let dataByKey = {}

            let currentPriceLine = [];
            let vaultTarget = [];
            let vaultBucket = [];
            let vaultBucketName;
            for (let i=0; i<this.monthlyStats.length; i++) {
                let dateFromString = new Date(this.monthlyStats[i][labelSource]);
                labels.push(months[dateFromString.getMonth()]);
                currentPriceLine.push(this.currentAsset.price);

                if (this.currentAsset?.meta?.vaultTarget) {
                    vaultTarget.push(this.currentAsset?.meta?.vaultTarget);
                }

                if (this.currentAsset?.meta?.vaultBucket) {
                    for (let j=0; j<this.monthlyStats[i].balances.length; j++) {
                        if (this.monthlyStats[i].balances[j].bucket_public_id == this.currentAsset?.meta?.vaultBucket) {
                            vaultBucket.push(this.monthlyStats[i].balances[j].shares);
                            vaultBucketName = this.monthlyStats[i].balances[j].bucket_name;
                        }
                    }
                }

                for (let x=0; x<dataLookup.length; x++) {
                    
                    if (!(dataLookup[x].key in dataByKey)) {
                        dataByKey[dataLookup[x].key] = []
                    }

                    if (dataLookup[x].key === 'free_shares') {
                        let freeShares = 'N/A';
                        if (this.monthlyStats[i].shares_holdings && (this.monthlyStats[i].shares_locked || this.monthlyStats[i].shares_locked === 0)) {
                            freeShares = this.monthlyStats[i].shares_holdings - this.monthlyStats[i].shares_locked;
                        }

                        dataByKey[dataLookup[x].key].push(this.formatNumber(freeShares));
                    } else if (dataLookup[x].key === 'profit_in_shares') {
                        // Profit in shares (value/current price)
                        let currentValueLocked = 0;
                        let profit = 0
                        if (this.monthlyStats[i].shares_holdings && this.monthlyStats[i].point_in_time_price) {
                            currentValueLocked = this.monthlyStats[i].shares_holdings * this.monthlyStats[i].point_in_time_price;
                            

                        }
                        if (currentValueLocked && this.monthlyStats[i].open_positions_value && this.monthlyStats[i].long_buys_value) {
                            profit = currentValueLocked - (this.monthlyStats[i].open_positions_value + this.monthlyStats[i].long_buys_value)
                        }

                        if (profit > 0) {
                            dataByKey[dataLookup[x].key].push(profit / this.monthlyStats[i].point_in_time_price);
                        } else {
                            dataByKey[dataLookup[x].key].push('N/A');
                        }
                    }
                    else {
                        dataByKey[dataLookup[x].key].push(this.formatNumber(this.monthlyStats[i][dataLookup[x].key] || 'N/A'));
                    }
                }
            }

            datasets.push({
                label: '###',
                data: currentPriceLine,
                borderColor: '#DA4644',
                yAxisID: 'y2',
                borderDash: [10,15],
                borderWidth: 1,
                //pointRadius: 1,
                pointStyle: 'cross',
                fill: false
            })

            if (vaultBucket && vaultBucketName) {
                datasets.push({
                    label: `In vault (${vaultBucketName})`,
                    backgroundColor: '#a8d4ad',
                    data: vaultBucket,
                    yAxisID: 'y1',
                });
            }
            
            for (let x=0; x<dataLookup.length; x++) {
                datasets.push({
                    label: dataLookup[x].label,
                    backgroundColor: dataLookup[x].backgroundColor,
                    data: dataByKey[dataLookup[x].key],
                    borderColor: dataLookup[x].borderColor || dataLookup[x].backgroundColor,
                    tension: 0.2,
                    yAxisID: dataLookup[x].yAxisID || 'y1',
                    borderWidth: dataLookup[x].borderWidth || 3,
                });
            }

            if (vaultTarget.length) {
                datasets.push({
                    label: `Vault target (${this.currentAsset?.meta?.vaultTarget})`,
                    backgroundColor: '#363B37',
                    data: vaultTarget,
                    //fill: false,
                    borderColor: '#a8d4ad',
                    yAxisID: 'y1',
                });
            }

            return {
                labels,
                datasets
            }
        }
    },
}
</script>

<style scoped>
.holdings-breakdown-chart {
    height: 40vh;
}
</style>
<template>
    <div class="default-style">
        <div class="future-styling-container">
            <h3 class="pb-3">
                <asset-symbol :asset="currentAsset" /> @<span>${{ formatPrice(checkPrice) }} </span>

                <span v-if="gotResponse" class="text-secondary">
                    {{ noPositionsItm }}
                </span>
            </h3>

            <div v-if="'oneLiner' in currentAsset.meta" class="text-info">
                {{ currentAsset.meta.oneLiner }}
            </div>

            <compact-position-list v-if="positions.length" :positionList="positions" :refPrice="checkPrice" class="py-3" />
        </div>

        <div v-if="!gotResponse" class="text-center">
            <b-spinner small label="loading..." type="grow" variant="light" class="mb-2"></b-spinner>
        </div>

        <div v-if="emptyResponse" class="text-center text-warning py-3">
            {{ emptyResponse }}
        </div>
        
        <div v-if="canSell">
            <b-row no-gutters>
                <b-col>
                    <b-form-select v-model="selected" :options="exitOptions"></b-form-select>
                </b-col>
                <b-col cols="4" class="text-right">
                    <b-overlay
                        :show="exitButtonBusy"
                        opacity="0.1"
                        spinner-small
                        spinner-variant="light"
                        class="d-inline-block"
                        >
                        <b-button 
                            ref="sellButton"
                            variant="success" 
                            :disabled="exitButtonBusy" 
                            @click.prevent="processOutcome(selected, 'exit')"
                            >
                            Sell ${{ formatPrice(selected*50) }}
                        </b-button>
                    </b-overlay>
                </b-col>
            </b-row>
        </div>
        <div v-else-if="canSell === false" class="text-center">
            <b-overlay
                :show="reinvestButtonBusy"
                opacity="0.1"
                spinner-small
                spinner-variant="light"
                class="d-inline-block"
                >
                <b-button 
                    ref="sellButton"
                    variant="warning" 
                    :disabled="exitButtonBusy" 
                    @click.prevent="processOutcome(positions.length, 'reinvest')"
                    >
                    Reinvest position{{ ((positions.length == 1) ?  '': 's') }}
                </b-button>
            </b-overlay>
        </div>

        <div class="mt-3 text-center">
            <b-button size="sm" @click="closeModal">Nevermind</b-button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from "vuex";

import formatter from '@/mixins/formatter';
import AssetSymbol from '@/components/style/AssetSymbol.vue';
import CompactPositionList from '@/components/asset/partials/CompactPositionList.vue';

export default {
    name: "ProcessExit",
    props: ['targetPrice', 'currentAsset', 'positionList'],
    mixins: [formatter],
    components: { 
        AssetSymbol, 
        CompactPositionList 
    },
    data() {
        return {
            gotResponse: false,
            emptyResponse: null,

            checkPrice: 0,
            positions: [],
            noPositionsItm: 0,
            maxSellablePositions: 0,

            selected: null,
            exitOptions: [],

            reinvestButtonBusy: false,
            exitButtonBusy: false,
        }
    },
    created() {
        if (this.positionList?.length) {
            this.positions = this.positionList;
            this.processExitOptions();
        }

        if (this.targetPrice) this.checkPrice = this.targetPrice;
        else this.checkPrice = this.currentAsset.price;
        this.checkExitOptions();
    },
    methods: {
        ...mapActions([
            'removePositionsFromActionable', 
            'registerPositionList',
        ]),
        closeModal() {
            this.$FModal.hide();
        },
        checkExitOptions() {
            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios.post(
                    this.$store.state.baseURL[this.$store.state.env] + '/api/asset/' + this.currentAsset.symbol + '/check_exits/' + this.checkPrice, 
                    JSON.stringify(this.form), {
                        headers: {
                            // Overwrite Axios's automatically set Content-Type
                            'Content-Type': 'application/json'
                        }
                    }
                )
                .then((response) => {
                    this.$store.dispatch('updateAssets', [response.data.asset]);
                    
                    if (response.data.no_positions_itm >= 3) this.maxSellablePositions = Math.floor((response.data.no_positions_itm - 1) / 2)

                    this.positions = response.data.positions;
                    this.noPositionsItm = response.data.no_positions_itm;
                    if (!this.positions.length) this.emptyResponse = 'Nothing to process at ' + this.formatPrice(response.data.target_price);
                    else this.processExitOptions();

                    this.gotResponse = true;
                })
                .catch((error) => {
                    console.log('errr ', error);
                });
        },
        processExitOptions() {
            let sumClosed = 0;
            let posExit = 0
            for (let i=1; i<= this.positions.length; i++) {
                if (i % 2) {
                    posExit += 1
                    if (posExit <= this.maxSellablePositions) {
                        sumClosed += this.positions[i-1].close_price;
                        this.exitOptions.push({ value: posExit, text: 50*posExit + ' @ ' + this.formatPrice(sumClosed/posExit)});
                        this.selected = posExit;
                    }
                }
            }
        },
        processOutcome(noPositions, outcome) {
            console.log('Process exit for '+noPositions+' '+this.currentAsset.symbol+' positions at target '+this.checkPrice);

            this.exitButtonBusy = true;

            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios.post(
                    this.$store.state.baseURL[this.$store.state.env] + '/api/positions/process-positions/', 
                    JSON.stringify({
                        type: outcome,
                        no_positions: noPositions,
                        asset_symbol: this.currentAsset.symbol,
                        target_price: this.checkPrice
                    }), {
                        headers: {
                            // Overwrite Axios's automatically set Content-Type
                            'Content-Type': 'application/json'
                        }
                    }
                )
                .then((response) => {
                    this.$store.dispatch('updateAssets', [response.data.asset]);
                    
                    let exitPids = []
                    let reinvestedPids = []
                    let newPositions = []
                    if ('exit_pids' in response.data) exitPids = response.data.exit_pids;
                    if ('reinvested_pids' in response.data) reinvestedPids = response.data.reinvested_pids;
                    if ('new_positions' in response.data) newPositions = response.data.new_positions;

                    let closedPids = [...exitPids, ...reinvestedPids];
                    //console.log('### closed and reinvested PIDs ', closedPids);
                    this.removePositionsFromActionable(closedPids);
                    this.registerPositionList(newPositions);
                    
                    this.exitButtonBusy = false;
                    // TODO: display Events somewhere
                    this.apiSuccessToast(response);
                    this.$FModal.hide();
                })
                .catch((error) => {
                    this.exitButtonBusy = false;
                    this.apiErrorToast(error);
                });
        }
    },
    computed: {
        canSell() {
            if (!this.gotResponse) return null;

            if (!this.positions.length || !this.maxSellablePositions) return false;

            return true;
        }
    }
};
</script>

<style>
    .default-style { 
        color: #fff; 
        padding: 30px 20px; 
    }
</style>
<template>
    <div class="dashboard-container">
        <rounded-container variant="dark" v-if="assets.length">
            <template v-slot:title>
                Overview
            </template>

            <list-assets-table 
                ref="assetTable" 
                :assetList="assets.filter(asset => 
                    asset.acts_like_conviction == 'high'
                    )" 
                />
        </rounded-container>

        <!-- Actionable Positions Table -->
        <positions 
            @suggest-buy="triggerBuyModal"
        />
        
        <last-added-positions :positionList="positionList" />
    </div>
</template>

<script>

import Positions from "../components/Positions.vue";
import LastAddedPositions from '../components/widgets/LastAddedPositions.vue';
import RoundedContainer from '../components/style/RoundedContainer.vue';
import ListAssetsTable from '../components/asset/ListAssetsTable.vue';

export default {
    name: "Dashboard",
    props: ['positionList', 'assets'],
    components: {
        Positions,
        LastAddedPositions,
        ListAssetsTable,
        RoundedContainer,
    },
    data() {
        return {
            actions: [],
        }
    },
    methods: {
        triggerBuyModal(symbol) {
            this.$emit('suggest-buy', symbol)
        },
    }
};
</script>

<style>
    .dashboard-container .asset-symbol-positions-no { display: none; }
</style>

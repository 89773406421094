<template>
    <rounded-container variant="dark">
        <template v-slot:title>Bookmarks</template>

        <b-row v-for="bookmark in bookmarks" :key="bookmark.public_id" class="py-2 px-3">
            <b-col>
                <a :href="bookmark.url" target="_blank">{{ bookmark.name }}</a>
            </b-col>
            <b-col cols="2">
                <b-icon icon="pencil-square" @click.prevent="editBookmarkForm(bookmark)"></b-icon>
            </b-col>
        </b-row>


        <b-form @submit.prevent="addOrEditBookmark" v-if="displayAddBookmarkForm" class="pt-3 px-3">
            <b-form-group label="URL" label-for="bookmark-url" label-size="sm" label-cols="2">
                <b-form-input type="url" id="bookmark-url" v-model="form.url" :disabled="form.bookmarkPublicId ? true : false" size="sm"></b-form-input>
            </b-form-group>

            <b-form-group label="Label" label-for="bookmark-name" label-size="sm" label-cols="2">
                <b-form-input type="text" id="bookmark-name" v-model="form.name" size="sm"></b-form-input>
            </b-form-group>

            <b-form-group label-size="sm" label-cols="2">
                <b-overlay
                    :show="submitButtonBusy"
                    opacity="0.1"
                    spinner-small
                    spinner-variant="light"
                    class="d-inline-block"
                >
                    <b-button    
                        :disabled="disableSubmitButton"
                        type="submit" 
                        size="sm" 
                        variant="primary"
                    >{{ submitButtonLabel }}</b-button>
                </b-overlay>

                <b-overlay
                    v-if="form.bookmarkPublicId"
                    :show="deleteButtonBusy"
                    opacity="0.1"
                    spinner-small
                    spinner-variant="light"
                    class="d-inline-block ml-2"
                >
                    <b-button
                        @click.prevent="deleteBookmark()"
                        size="sm" 
                        variant="danger"
                    >delete</b-button>
                </b-overlay>

                <b-button @click.prevent="discardAddBookmarkForm" size="sm" class="ml-2">
                    discard
                </b-button>
            </b-form-group>

            <b-form-group v-if="errorMessage" label-size="sm" label-cols="2" >
                <div class="text-warning">{{ errorMessage }}</div>
            </b-form-group>
        </b-form>

        <div class="text-center">
            <b-button 
                v-if="!displayAddBookmarkForm"
                @click.prevent="showAddBookmarkForm" 
                variant="link" 
                class="text-decoration-none text-secondary"
            >
                + add bookmark
            </b-button>
        </div>
    </rounded-container>
</template>

<script>

import axios from 'axios';
import RoundedContainer from '@/components/style/RoundedContainer.vue';

export default {
    name: 'AssetBookmarks',
    props: ['bookmarks', 'currentAsset'],
    components: {
        RoundedContainer,
    },
    data() {
        return {
            displayAddBookmarkForm: false,
            form: {
                bookmarkPublicId: null,
                url: null,
                name: null
            },
            submitButtonBusy: false,
            deleteButtonBusy: false,
            errorMessage: null,
        }
    },
    methods: {
        resetForm() {
            this.form.bookmarkPublicId = null;
            this.form.url = null;
            this.form.name = null;
        },
        showAddBookmarkForm() {
            this.resetForm()
            this.displayAddBookmarkForm = true;
        },
        discardAddBookmarkForm() {
            this.resetForm()
            this.displayAddBookmarkForm = false;
        },
        editBookmarkForm(bookmark) {
            this.resetForm();
            this.displayAddBookmarkForm = true;
            this.form.bookmarkPublicId = bookmark.public_id;
            this.form.url = bookmark.url;
            this.form.name = bookmark.name;
        },
        addOrEditBookmark() {
            if (!this.form.url) return;

            this.submitButtonBusy = true;
            let payload = {
                url: this.form.url,
                name: this.form.name
            }

            let add_endpoint = `/api/asset/${this.currentAsset.symbol}/add_bookmark`;
            let edit_endpoint = `/api/asset/${this.currentAsset.symbol}/bookmark/${this.form.bookmarkPublicId}`;
            let endpoint = this.$store.state.baseURL[this.$store.state.env]
                            + (this.form.bookmarkPublicId ? edit_endpoint : add_endpoint);
            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            
            return axios.post(endpoint, payload, {
                        headers: {
                            // Overwrite Axios's automatically set Content-Type
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        this.$emit('update-bookmarks', response.data.bookmarks);
                        this.$store.dispatch('updateAssets', [response.data.asset]);

                        this.resetForm()
                        this.displayAddBookmarkForm = false;
                        this.submitButtonBusy = false;
                    })
                    .catch((error) => {
                        console.log(error)
                        this.submitButtonBusy = false;

                        // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                        if (error.request.status == 401) {
                            localStorage.removeItem('access_token');
                            this.$store.context.commit('destroyAccessToken');
                        }
                    });

            
        },
        deleteBookmark() {
            if (!this.form.bookmarkPublicId) return;

            this.deleteButtonBusy = true;
            let endpoint = this.$store.state.baseURL[this.$store.state.env] 
                            + `/api/asset/${this.currentAsset.symbol}/bookmark/${this.form.bookmarkPublicId}`;
            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            
            return axios.delete(endpoint)
                    .then((response) => {
                        this.$emit('update-bookmarks', response.data.bookmarks);
                        this.$store.dispatch('updateAssets', [response.data.asset]);

                        this.resetForm()
                        this.displayAddBookmarkForm = false;
                        this.deleteButtonBusy = false;
                    })
                    .catch((error) => {
                        console.log(error)
                        this.deleteButtonBusy = false;

                        // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                        if (error.request.status == 401) {
                            localStorage.removeItem('access_token');
                            this.$store.context.commit('destroyAccessToken');
                        }
                    });
        }
    },
    computed: {
        disableSubmitButton() {
            if (this.form.url?.slice(0,8) === 'https://' && this.form.url?.length > 12) return false;
            return true;
        },
        submitButtonLabel() {
            if (this.form.bookmarkPublicId) return 'edit';
            return 'add';
        }
    }
}
</script>

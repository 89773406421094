<template>
    <div id="app" align-h="center">
        <div v-if="isDevelopmentAPI" class="development-warning text-center bg-info p-3">
            <strong>API</strong>: 
            <b-badge variant="warning">DEVELOPMENT</b-badge> <span>&nbsp;</span> 
            <strong>DB</strong>: 
            <b-badge v-if="dbUsed == 'development'" variant="warning">{{ dbUsed }}</b-badge>
            <b-badge v-else variant="danger">{{ dbUsed }}</b-badge>
        </div>

        <div v-if="!loadingData">
            <router-view
                :assets="assets"
                @suggest-buy="openBuy"
                @asset-add-balance="setBalance"
                @asset-add-group="addAssetGroup"
                @manage-group="manageGroup"
                />
        </div>
        <div v-else class="loading-screen text-center text-secondary p-5 mt-5">
            <b-spinner variant="secondary"></b-spinner><br /><br />
            Loading Assets...
        </div>
        
        <div v-if="loggedIn" class="text-center p-3">
            {{ getLastSyncReadable }}

            <span class="text-secondary">{{ getEnv }}</span>

            <div class="pb-5"></div>
            <div class="pb-5"></div>
        </div>

        <app-navbar id="navbar"
            :todaysActivity="getTodaysActivityComputed"
            :class="syncStatus" 
            v-if="loggedIn" 
            class="px-2 pt-2 pb-1 mb-2"
            @suggest-buy="openBuy"
            />
    </div>
</template>

<script>
import SuggestBuy from './components/overlays/SuggestBuy.vue'
import AddBalance from './components/overlays/AddBalance.vue'
import AddGroup from './components/overlays/AddGroup.vue'
import ManageGroup from './components/overlays/ManageGroup.vue'

import { mapState, mapGetters } from 'vuex';
import AppNavbar from './components/AppNavbar.vue';

import bucketMixin from './mixins/bucketMixin';

export default {
    name: "App",
    mixins: [bucketMixin],
    components: {
        AppNavbar,
    },
    data() {
        return {
            modalStyle: { 
                backgroundColor: '#000',

                contentStyles: { 
                    borderRadius: '10px 10px 0px 0px',
                    borderTop: '3px solid #333'
                } 
            },
        };
    },
    async created() {
        //get('assets').then((value) => this.$store.dispatch('updateAssets', value));
        
        this.checkUserSession();

        await this.fetchBucketList()
    },
    methods: {
        refreshData: function() {
            // called from methods AND from template
            this.$store.dispatch('syncData');
        },
        checkUserSession: async function() {            
            if (this.$store.getters.loggedIn) {
                // active session detected
                this.refreshData();

            } else if (this.$router.currentRoute.name != 'login') {
                // session expired
                this.$store.dispatch('disableSyncTimer');
                this.$router.push({ name: 'login' });
            } else {   
                // when on login page
                this.$store.dispatch('disableSyncTimer');
            }
        },
        openBuy(symbol = null) {
            this.$FModal.show(
                {
                    component: SuggestBuy,
                    ...this.modalStyle
                },
                { symbol }
            )
        },
        setBalance(asset) {
            this.$FModal.show(
                {
                    component: AddBalance,
                    ...this.modalStyle
                },
                { assetContext: asset }
            )
        },
        addAssetGroup(groupData) {
            this.$FModal.show(
                {
                    component: AddGroup,
                    ...this.modalStyle
                },
                { 
                    assetContext: groupData.asset,
                    groupType: groupData.defaultGroupType,
                    defaultDrawSize: ((groupData.defaultDrawSize) ? groupData.defaultDrawSize : null),
                    defaultMultiplier: ((groupData.defaultMultiplier) ? groupData.defaultMultiplier : null),
                    defaultOpenPrice: ((groupData.defaultOpenPrice) ? groupData.defaultOpenPrice : null),
                    defaultGroupName: ((groupData.defaultGroupName) ? groupData.defaultGroupName : null),
                }
            )
        },
        manageGroup(groupData) {
            this.$FModal.show(
                {
                    component: ManageGroup,
                    ...this.modalStyle
                },
                { 
                    assetContext: groupData.asset,
                    groupContext: groupData.group,
                }
            )
        }
    },
    computed: {
        ...mapState({
            assets: state => state.assets.assetList,
        }),
        ...mapGetters([
            'getLastSyncReadable',
            'actionableToSell',
            'actionableToBuy',
            'getEnv',
            'getTodaysActivityComputed',
        ]),
        isDevelopmentAPI() {
            return ((this.getEnv == 'development') ? true : false);
        },
        dbUsed() {
            return this.$store.state.dbUsed;
        },
        loggedIn() {
            return this.$store.getters.loggedIn;
        },
        showRefresh() {
            return this.$store.state.canSync;
        },
        navbarVariant() {
            if (this.$store.getters.isProd) return 'dark';
            else return 'danger';
        },
        syncStatus() {
            let status = this.$store.state.syncStatus;

            if (status == 'synced') {
                let hasActions = this.actionableToSell + this.actionableToBuy 
                if (hasActions >= 10) {
                    return 'status_lotsof_actions';
                }
                else if (hasActions) {
                    return 'stats_got_actions';
                }
            } 
            
            return 'status_'+status;
        },
        loadingData() {
            if (!this.loggedIn) return false

            if (this.loggedIn && this.assets && this.assets.length) return false

            return true
        }
    },
    watch: {
        loggedIn(newState, prevState) {
            if (newState != prevState) { // hack ca sa folosesc var names din fn declaration
                this.checkUserSession();
            }
        }
    }
    
};
</script>

<style lang="scss">

@import 'styles/_custom.scss';
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300&display=swap");

#app {
    font-family: "Karla", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    margin-bottom: 100px;
    padding: 0px;
    max-width: 600px;
    margin: 0 auto;
}

#app .app_brand {
    background: linear-gradient(130deg,#9659f8 -6.8%,#e83e8c 70%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}

#app .formatPrice { 
    font-family: var(--font-family-monospace);
    font-weight: normal;
}

#app .development-warning {
    text-transform: uppercase;
}

#navbar { 
    position: fixed;
    bottom: 0px;
    z-index: 999;
    width: 100%;
    max-width: 600px;
    border-bottom: 3px solid #e83e8c;
}
#navbar.status_not_synced {
    border-color: #e83e8c;
}
#navbar.status_syncing {
    border-color: #9659f8;
}
#navbar.status_syncing .sync-data {
    color: #9659f8;
} 
#navbar.status_processing {
    border-color: #ffc107;
}
#navbar.status_processing .sync-data {
    color: #ffc107;
}
#navbar.status_getActionable {
    border-color: #ff8b07;
}
#navbar.status_synced {
    border-color: #343a40;
}
#navbar.status_got_actions {
    border-color: #B2FFD6;
}
#navbar.status_lotsof_actions {
    border-color: #e83e8c
}

.modal-style { 
        color: #fff; 
        padding: 30px 15px 50px 15px; 
    }

.custom-table-head {
    background-color: hsla(0,0%,100%,.05);
}
.oddRow {
    background-color: hsla(0,0%,100%,.05);
}
.format-price {
    font-family: var(--font-family-monospace);
    font-size: 0.9em;
    line-height: 1.8em;
}
.strikethrough {
    text-decoration: line-through;
}
</style>

<template>
    <div>
        <div v-if="currentAsset">
            <asset-navbar :filterMarket="currentAsset.market">
                <template v-slot:title>
                    {{ symbol }}
                </template>
                <template v-slot:subTitle>
                        <span class="mr-2">${{ formatPrice(currentAsset.price) }}</span>
                        
                        <span class="text-danger" v-if="getDistance(JSON.parse(currentAsset.meta.lowest_position).open_price, currentAsset.price).value < 0">{{ getDistance(JSON.parse(currentAsset.meta.lowest_position).open_price, currentAsset.price).formatted }}</span>
                        <span v-else>{{ getDistance(JSON.parse(currentAsset.meta.lowest_position).open_price, currentAsset.price).formatted }}</span>
                </template>
            </asset-navbar>

            <!-- Strategy One Liner -->
            <rounded-container variant="highlight" v-if="'oneLiner' in currentAsset.meta && currentAsset.meta.oneLiner">
                
                <template v-slot:title>Strategy One Liner</template>

                <b-container class="py-3">
                    {{ currentAsset.meta.oneLiner}}
                </b-container>
            </rounded-container>

            <!-- Highlights -->
            <div class="p-2">
                <b-row class="asset-highlights mt-3 text-center" no-gutters>
                    <b-col>
                        <h3>${{ formatHoldings(balanceTotalValue) }}</h3> 
                        <span class="text-secondary">holdings<br /> value</span>
                    </b-col>
                    
                    <!-- <b-col>
                        <h3 v-if="valueLocked < balanceTotalValue">${{ formatHoldings(valueLocked) }}</h3>
                        <h3 v-else class="text-danger">${{ formatHoldings(valueLocked) }}</h3>
                        <span class="text-secondary">value<br />locked</span>
                    </b-col> -->

                    <b-col v-if="netProfit >= 0">
                        <h3>${{ formatHoldings(netProfit) }}</h3> 
                        <span class="text-secondary">{{ profitPercentage }}%<br /> profit</span>
                    </b-col>
                    <b-col v-else class="text-danger">
                        <h3>-${{ formatHoldings(Math.abs(netProfit)) }}</h3> 
                        <span class="text-secondary">{{ profitPercentage }}%<br /> profit</span>
                    </b-col> 

                    <b-col v-if="!gotAssetData" class="text-center text-warning">
                        <h3>${{ formatHoldings(offset * currentAsset.price) }}</h3>
                        <strong class="format-price">{{ formatHoldings(offset) }}</strong><br />
                        <span class="text-secondary">offset</span>
                    </b-col>
                    <b-col v-else-if="offset > 0" class="text-center text-success">
                        <h3>${{ formatHoldings(offset * currentAsset.price) }}</h3>
                        <strong class="format-price">{{ formatHoldings(offset) }}</strong><br />
                        <span class="text-secondary">offset</span>
                    </b-col>
                    <b-col v-else-if="netProfit > 0" class="text-center">
                            <h3>-${{ formatHoldings(Math.abs(offset * currentAsset.price)) }}</h3>
                            <strong class="format-price">{{ formatHoldings(offset) }}</strong><br />
                            <span class="text-secondary">offset</span>
                    </b-col>
                    <b-col v-else class="text-center text-danger">
                            <h3>-${{ formatHoldings(Math.abs(offset * currentAsset.price)) }}</h3>
                            <strong class="format-price">{{ formatHoldings(offset) }}</strong><br />
                            <span class="text-secondary">offset</span>
                    </b-col> 
                </b-row>

                <b-row class="asset-highlights mt-4 text-center text-secondary" no-gutters>
                    <b-col>
                        <h3>${{ formatHoldings(valueLocked) }}</h3>
                        <span class="text-secondary">value<br />locked</span>
                    </b-col>

                    <b-col>
                        <h3>${{ formatHoldings(currentAsset.cached_stats.sum_bonus_exit_value || 0) }}</h3>
                        <span class="text-secondary">all time<br />bonus buys</span>
                    </b-col>

                    <b-col>
                        <h3>${{ formatHoldings(currentAsset.cached_stats.stale_positions_value || 0) }}</h3>
                        <span class="text-secondary">stale<br />value</span>
                    </b-col>
                </b-row>
            </div>

            <!-- Recent Events -->
            <rounded-container variant="dark">
                <template v-slot:title>Recent events</template>

                <div class="pt-3 px-1 pb-1" v-if="bubbleChartData.datasets.length">
                    <bubble-chart
                        :chart-data="bubbleChartData"
                        :styles="{ position: 'relative', width: '96%', margin: 'auto', height: '300px' }"
                    />
                </div>
                
                <b-container>
                    <div v-if="!events.length" class="text-center py-3">
                        <span v-if="!gotAssetData">Loading...</span>
                        <span v-else>No recent events</span>
                    </div>
                    
                    <event-list v-if="events.length" :eventList="events" ></event-list>
                </b-container>
            </rounded-container>

            <!-- Holdings breakdown chart -->
            <!-- Balances -->
            <rounded-container variant="dark">
                <template v-slot:title>Holdings and balances</template>
                
                <!-- Holdings breakdown chart -->
                <holdings-breakdown-chart 
                    v-if="monthlyStats.length"
                    :currentAsset="currentAsset" 
                    :monthlyStats="monthlyStats" 
                />

                <!-- Balances -->
                <b-container class="px-0" v-if="currentAsset.balances.length || monthlyStats.length">
                    <b-row 
                        v-for="(balance, rowIndex) in currentAsset.balances" 
                        :key="balance.public_id"
                        :class="balanceRowClass(rowIndex, balance.shares)"
                        class="px-3 py-2"
                    >
                        <b-col>
                            {{ balance.bucket.name }}&nbsp;<span v-if="balance.bucket.type == 'trading'" class="text-info">&#8226;</span>
                        </b-col>
                        <b-col class="format-price">{{ formatHoldings(balance.shares) }}</b-col>
                        <b-col class="format-price">${{ formatHoldings(balance.shares * currentAsset.price) }}</b-col>
                        <b-col>{{ formatTimeFromISO(balance.last_check + 'Z') }}</b-col>
                    </b-row>

                    <b-row class="px-3 py-2 text-secondary">
                        <b-col>
                            Holdings
                        </b-col>
                        <b-col class="format-price">
                            {{ formatHoldings(sumHoldings(currentAsset.balances, 'trading') + balanceLongBuckets) }}
                        </b-col>
                        <b-col>&nbsp;</b-col>
                        <b-col>&nbsp;</b-col>
                    </b-row>
                </b-container>

                <!-- Require asset refresh -->
                <b-container class="py-3 text-center">
                    <div v-if="requireAssetRefresh">
                        <b-overlay
                            :show="assetRefreshing"
                            rounded
                            opacity="0.1"
                            spinner-small
                            spinner-variant="light"
                            class="d-inline-block"
                        >
                            <b-button @click.prevent="refreshAsset" pill>refresh {{ currentAsset.symbol }}</b-button>
                        </b-overlay>
                    </div>
                    <div v-else>
                        <b-button @click.prevent="addBalance" pill variant="dark" class="mr-2">add balance</b-button>
                        <b-button @click.prevent="suggestBuy" pill>add position</b-button>
                    </div>
                </b-container>
            </rounded-container>

            <rounded-container variant="dark">
                <template v-slot:title>
                    Exits distribution
                </template>

                <div class="py-1">
                    <b-row 
                        v-for="(distribution, rowIndex) in positionDistribution" 
                        :key="distribution.bracket" 
                        :class="rowClass(rowIndex)" 
                        class="px-3 py-2"
                    >
                        <b-col cols="3">{{ distribution.bracket.replace('P', '%') }}</b-col>
                        <b-col cols="4" class="format-price">${{ formatPrice(distribution.value) }}</b-col>
                        <b-col cols="2">{{ formatNumber(distribution.starting_price) }}</b-col>
                        <b-col cols="1">⇢</b-col>
                        <b-col cols="2">{{ formatNumber(distribution.end_price) }}</b-col>
                    </b-row>
                </div>

                <!-- Exits at target -->
                <asset-exits-at-target :currentAsset="currentAsset" />
            </rounded-container>


            <!-- This month's activity -->







            <!-- Bookmarks -->
            <asset-bookmarks 
                :bookmarks="bookmarks" 
                :currentAsset="currentAsset" 
                @update-bookmarks="updateBookmarkList"
            />

            <!-- Groups -->
            <group-list 
                :groupList="computedGroups" 
                :asset="currentAsset" 
                :requireRefresh.sync="requireAssetRefresh"
                @asset-add-group="addAssetGroup" 
                @manage-group="manageGroup"
                @request-refresh="refreshAsset"
                @require-refresh="requireRefresh"
                />

            <!-- Edit Asset Meta -->
            <edit-asset-meta :currentAsset="currentAsset" @request-asset-refresh="requireRefresh" />

            <div class="asset_stats my-5" v-if="!gotAssetData">
                <div class="text-center py-5">
                    <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                </div>
            </div>
            <div v-else>
                <!-- Open Positions -->
                <rounded-container variant="dark">
                    <template v-slot:title>{{ openPositionsCount }} open positions<span class="text-secondary" v-if="poopPecentage">, {{ poopPecentage }}% 💩</span></template>

                    <b-table 
                        striped 
                        :items="openPositionsFirstHalf"
                        :fields="openPositionFields"
                        class="table table-dark mb-0"
                        :sort-by.sync="openSortBy"
                        :sort-desc.sync="openSortDesc"
                    >
                        <template v-slot:cell(size)="row">
                            {{ row.item.size }}
                            <span v-if="row.item.open_type == 'reinvested_from_stale'">💩</span>
                        </template>
                    </b-table>

                    <div class="text-center p-1" v-if="openPositionsSecondHalf.length">
                        - - -
                    </div>

                    <b-table 
                        striped 
                        :items="openPositionsSecondHalf"
                        :fields="openPositionFields"
                        class="table table-dark mb-0"
                        :sort-by.sync="openSortBy"
                        :sort-desc.sync="openSortDesc"
                        thead-tr-class="d-none"
                    >
                        <template v-slot:cell(size)="row">
                            {{ row.item.size }}
                            <span v-if="row.item.open_type == 'reinvested_from_stale'">💩</span>
                        </template>
                    </b-table>
                </rounded-container>

                <!-- Closed Positions -->
                <rounded-container>
                    <template v-slot:title>{{ closedPositions.length }} closed in the last 30 days</template>

                    <b-table 
                        striped 
                        :items="closedPositions" 
                        :fields="closedPositionFields"
                        class="table table-dark mb-0 strikethrough"
                        :sort-by.sync="closedSortBy"
                        :sort-desc.sync="closedSortDesc"
                    >
                    </b-table>
                </rounded-container>


                <!-- Debug stuff -->
                <rounded-container v-if="currentAsset" variant="dark">
                    <template v-slot:title>View JSON</template>

                    <b-container class="p-2 text-center">
                        <router-link 
                            :to="{  
                                name: 'jsonViewer', 
                                params: { 
                                    json: currentAsset, 
                                    objectName: 'asset',
                                    returnTo: {
                                        destination: 'asset',
                                        value: currentAsset.symbol
                                    }
                                } 
                            }"
                        >
                            asset
                        </router-link>
                    </b-container>
                </rounded-container>

                <!-- Stats for nerds -->
                <div class="p-2">
                    <h3>Stats for nerds</h3>
                    
                    <b-row class="asset-highlights mt-4 text-center text-secondary" no-gutters>
                        <b-col>
                            <h3>${{ formatPrice(currentAsset.cached_stats.most_exited_at_price) ? formatPrice(currentAsset.cached_stats.most_exited_at_price) : 'N/A' }}</h3>
                            <span class="text-secondary">price when<br />exited 70%</span>
                        </b-col>
                        <b-col>
                            <h3>${{ formatPrice(currentAsset.cached_stats.last_exit_price) ? formatPrice(currentAsset.cached_stats.last_exit_price) : 'N/A' }}</h3>
                            
                            <span class="text-secondary">last sim.<br />exit price</span>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import formatter from '../mixins/formatter'
import assetMixin from '../mixins/assetMixin'

import RoundedContainer from '@/components/style/RoundedContainer.vue';
import EditAssetMeta from '../components/asset/EditAssetMeta.vue';
import AssetExitsAtTarget from '@/components/asset/partials/AssetExitsAtTarget.vue'
//import AssetData from '../components/asset/AssetData.vue';
//import ActivityChart from '@/components/stats/ActivityChart.vue';
import BubbleChart from '@/components/charts/BubbleChart';
import GroupList from '@/components/GroupList.vue';
import AssetNavbar from '@/components/AssetNavbar.vue';
import EventList from '@/components/EventList.vue';
import AssetBookmarks from '@/components/asset/partials/AssetBookmarks.vue';
import HoldingsBreakdownChart from '@/components/asset/partials/HoldingsBreakdownChart.vue';

export default {
    props: ['symbol', 'pid', 'assetContext'],
    name: 'Asset',
    mixins: [
        formatter, 
        assetMixin
    ],
    components: {
        RoundedContainer,
        EditAssetMeta,
        //AssetData,
        HoldingsBreakdownChart,
        //ActivityChart,
        GroupList,
        AssetNavbar,
        AssetExitsAtTarget,
        EventList,
        AssetBookmarks,
        BubbleChart,
    },

    data() {
        return {
            currentAsset: null,
            requireAssetRefresh: false,
            assetRefreshing: false,

            gotAssetData: false,
            mainPosition: {},
            tradingHoldings: 0,
            longHoldings: 0,

            monthlyStats: [],

            bubbleChartData: {
                datasets: [],
            },

            openPositions: [],
            openPositionsCount: 0,
            positionDistribution: [],
            closedPositions: [],

            groups: [],
            events: [],
            bookmarks: [],

            openPositionFields: [
                {
                    key: 'open_date',
                    label: 'date',
                    sortable: true
                },
                {
                    key: 'size',
                    label: 'size',
                    sortable: false
                },
                {
                    key: 'open_price',
                    label: 'open',
                    sortable: true
                },
                {
                    key: 'close_price',
                    label: 'close',
                    sortable: false
                }
            ],
            closedPositionFields: [
                {
                    key: 'close_date',
                    label: 'date',
                    sortable: true
                },
                {
                    key: 'size',
                    label: 'size',
                    sortable: false
                },
                {
                    key: 'open_price',
                    label: 'open',
                    sortable: true
                },
                {
                    key: 'close_price',
                    label: 'close',
                    sortable: false
                }
            ],
            openSortBy: 'open_price',
            openSortDesc: false,
            closedSortBy: 'close_date', 
            closedSortDesc: true,
        }
    },
    created() {
        if (this.assetContext) this.currentAsset = this.assetContext
        else this.currentAsset = this.$store.getters.getAssetBySymbol(this.symbol);
        
        this.retrieveAssetData().then((asset) => {
            if (asset.meta && ('conviction' in asset.meta)) {
                this.convictionSelected = asset.meta.conviction;
            }
        });
    },
    methods: {
        balanceRowClass(rowIndex, balance) {
            if (balance) return this.rowClass(rowIndex);
            return [this.rowClass, 'text-secondary'];
        },
        closedAboveGetClass(market) {
            if (market != this.currentAsset.market) return 'text-secondary'
            return ''
        },
        sumHoldings(balances, trading_or_long) {
            let holdings = 0

            for (let i=0; i < balances.length; i++) {
                if (balances[i].bucket.type == trading_or_long) {
                    holdings += balances[i].shares
                }
            }

            if (trading_or_long == 'trading') this.tradingHoldings = holdings
            if (trading_or_long == 'long') this.longHoldings = holdings

            return holdings
        },
        getDistance(open_price, current_price) {
            let distance = Math.round((current_price / open_price - 1) * 1000) / 10;

            return {
                value: distance,
                formatted: (distance > 0 ? '+'+distance: ''+distance) +'%'
            }
        },
        formatPrice(price) {
            var formatter = new Intl.NumberFormat('en-US');

            if (price > 0.1) return formatter.format(price);
            return price
        },
        suggestBuy() {
            this.$emit('suggest-buy', this.currentAsset.symbol);
            this.requireAssetRefresh = true;
        },
        addBalance() {
            this.$emit('asset-add-balance', this.currentAsset);
            this.requireAssetRefresh = true;
        },
        manageGroup(groupData) {
            this.manageGroupModal(groupData)
            this.requireAssetRefresh = true;
        },
        addAssetGroup() {
            this.$emit('asset-add-group', {
                defaultGroupType: 'long_buy',
                asset: this.currentAsset
            });
            this.requireAssetRefresh = true;
        },
        refreshAsset() {
            this.assetRefreshing = true
            this.retrieveAssetData().then(() => {
                this.assetRefreshing = false
                this.requireAssetRefresh = false
            })
           
        },
        requireRefresh() {
            this.requireAssetRefresh = true
        },
        mainClass(mainType) {
            if (mainType == 'build-up') {
                return 'main_buildup'
            } else if (mainType == 'paid off') {
                return 'main_paidoff'
            } else {
                return 'main_default'
            }
        },
        roundTwoDecimals(num) {
            return Math.round((num + Number.EPSILON) * 100) / 100
        },
        debugMode() {
            if (this.$store.state.env == 'dev') return true;
            return false;
        },
        updateBookmarkList(bookmarkList) {
            this.bookmarks = bookmarkList;
        },
        async retrieveAssetData() {
            this.gotAssetData = false;

            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            return axios
                .get(this.$store.state.baseURL[this.$store.state.env] + '/api/asset/' + this.symbol)
                .then((response) => {
                    this.currentAsset = response.data.asset;
                    this.positionDistribution = response.data.position_distribution;
                    this.groups = response.data.groups;
                    this.$store.dispatch('updateAssets', [this.currentAsset]);

                    this.events = response.data.events;
                    this.bookmarks = response.data.bookmarks;

                    this.monthlyStats = response.data.monthly_stats;

                    const { data_buys, data_largebuys, data_exits, no_days } = response.data.chart_n_days_activity;

                    let lineData = []
                    for (let i = (-1 * no_days); i <= 0; i++) {
                        lineData.push({
                            y: this.currentAsset.price,
                            x: i,
                            r: 0
                        });
                    }
                    let bubbleDatasets = [{
                        label: 'Current price',
                        type: 'line',
                        backgroundColor: '#141414',
                        data: lineData,
                        pointStyle: 'dash'
                    }]

                    if (data_buys.length) {
                        bubbleDatasets.push({
                            label: 'Buys',
                            type: 'bubble',
                            backgroundColor: '#eb6534',
                            data: data_buys,
                        })
                    }
                    
                    if (data_largebuys.length) {
                        bubbleDatasets.push({
                            label: 'Large Buys',
                            type: 'bubble',
                            backgroundColor: '#CC4514',
                            data: data_largebuys,
                            pointStyle: 'rectRot'
                        })
                    }
                    
                    if (data_exits.length) {
                        bubbleDatasets.push({
                            label: 'Exits',
                            type: 'bubble',
                            backgroundColor: '#72cba0',
                            data: data_exits,
                        })
                    } 

                    if (bubbleDatasets.length > 1) {
                        this.bubbleChartData = {
                            datasets: bubbleDatasets
                        }
                    }
                    

                    this.openPositions = response.data.open_positions;
                    this.openPositionsCount = response.data.open_positions_count;
                    this.closedPositions = response.data.closed_positions;
                    
                    this.groups = response.data.groups;

                    this.gotAssetData = true;
                    
                    // for debugging
                    this.receivedPayload = response.data;

                    return response.data.asset;
                })
                .catch((error) => {
                    // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                    if (error.request.status == 401) {
                        localStorage.removeItem('access_token');
                        this.$store.context.commit('destroyAccessToken');
                    }
                });
        }
    },
    computed: {
        offset() {
            return this.tradingHoldings - this.currentAsset.locked_shares - this.sumPendingGroupShares
        },
        countReinvestedFromStale() {
            let count = 0
            
            for (let i=0; i<this.openPositions.length; i++) {
                if (this.openPositions[i].open_type == 'reinvested_from_stale') count += 1        
            }

            return count
        },
        poopPecentage() {
            if (this.countReinvestedFromStale) {
                return (this.countReinvestedFromStale / this.openPositions.length * 100).toFixed()
            }

            return 0
        },
        balanceLongBuckets() {
            return this.sumHoldings(this.currentAsset.balances, 'long')
        },
        balanceTotal() {
            return (parseFloat(this.tradingHoldings) + parseFloat(this.balanceLongBuckets))
        },
        balanceTotalValue() {
            return (this.balanceTotal * this.currentAsset.price).toFixed()
        },
        sumPendingGroupShares() {
            let sumShares = 0

            for (let i=0; i < this.groups.length; i++) {
                if (this.groups[i].stage != 'converted' && this.groups[i].stage != 'closed') {
                    sumShares += (this.groups[i].value / (this.groups[i].open_price * this.groups[i].multiplier))
                }
            }

            return sumShares
        },
        sumPendingGroupLockedValue() {
            let sumValue = 0

            for (let i=0; i < this.groups.length; i++) {
                if (this.groups[i].stage != 'converted' && this.groups[i].stage != 'closed') {
                    sumValue += this.groups[i].value
                }
            }

            return sumValue
        },
        valueLocked() {
            return this.sumPendingGroupLockedValue 
                    + this.currentAsset.cached_stats.open_positions_value
                    - (this.currentAsset.cached_stats.sum_bonus_exit_value || 0)
        },
        netProfit() {
            return (
                this.balanceTotalValue - 
                (this.currentAsset.cached_stats.open_positions_value - (this.currentAsset.cached_stats.sum_bonus_exit_value || 0)) -
                this.sumPendingGroupLockedValue
            )
        },
        profitPercentage() {
            return ((this.netProfit / this.valueLocked) * 100).toFixed(1)
        },
        computedGroups() {
            return this.groups
        },
        openPositionsFirstHalf() {
            return this.openPositionsCount > 40 ? this.openPositions.slice(0, 20) : this.openPositions;
        },
        openPositionsSecondHalf() {
            return this.openPositionsCount > 40 ? this.openPositions.slice(-20) : [];
        },
    },
    watch: {
        symbol() {
            // when going through assets without changing the page, a force reload is required
            this.retrieveAssetData();
        },
    },
};
</script>

<style scoped>
    .asset-highlights h3 {
        margin-bottom: 0px;
    }
    .asset-highlights .text-secondary {
        font-size: 16px;
    }
</style>